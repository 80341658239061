



























































































































































































.widget-settings {
    .nav-tabs {
        justify-content: center;
        a {
            opacity: 0.6;
            text-align: center;
            &.active {
                background: transparent;
                color: #495057;
                opacity: 1;
            }
        }
        .nav-item {
            flex: 1 1 auto;
        }
    }

    .boxFile {
        .input-group {
            background-color: #4284f4;
            border-color: 1px solid #4284f4;
            border-radius: 0.3rem;
            color: #fff;
            input[type='file'] {
                cursor: pointer;
            }
            &::before {
                position: absolute;
                top: 9px;
                left: calc(50% - 45px);
                font-weight: 600;
                color: #fff;
                pointer-events: none;
                content: 'Upload File';
            }
            &:hover {
                background-color: #1e6cf2;
                border-color: #1265f1;
                &::before {
                  color: #fff;
                }
            }
        }
        .form-control {
            opacity: 0;
        }
    }
}

